import {NgxUiLoaderConfig} from "ngx-ui-loader";

export let loaderConfig : NgxUiLoaderConfig = {
  bgsColor: "#0a75df",
  bgsOpacity: 0.5,
  bgsPosition: "bottom-right",
  bgsSize: 60,
  bgsType: "ball-scale-multiple",
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "#0a75df",
  fgsPosition: "center-center",
  fgsSize: 60,
  fgsType: "ball-scale-multiple",
  gap: 24,
  logoPosition: "center-center",
  logoSize: 220,
  logoUrl: "",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.9)",
  pbColor: "#0a75df",
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: false,
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 300
}
