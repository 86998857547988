import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../auth.service";
import {AuthGuardService} from "./auth.guard";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuardService  {

  private allowedUserTypes: Number[] | undefined;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const userType: number = this.authService.getUserTypeForAuthorization();

    this.allowedUserTypes = route.data['allowedUserTypes'];

    const isFirstTimeLogin = this.authService.getFirstTimeLogin();

    if(isFirstTimeLogin){
      this.router.navigate(['change-password'])
      return false;
    }

    if (this.allowedUserTypes && this.allowedUserTypes.indexOf(userType) > -1) {
      return true;
    }

    let defaultRoute = this.authService.getDefaultAuthorizedDashboardRoute(userType);
    localStorage.setItem('defaultRoute', defaultRoute)
    this.router.navigate([defaultRoute]).then();
    return false;
  }
}

export const AuthorizationGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  => {
  return inject(AuthorizationGuardService).canActivate(next, state);
}
