// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { DefaultBrowserBehavior, DefaultDeviceController, VideoTransformDevice } from 'amazon-chime-sdk-js';
import MediaStreamProvider from './MediaStreamProvider';

/**
 * [[AudioBufferMediaStreamProvider]] creates a `MediaStream` from a parsed
 * audio buffer file.
 */
export class AudioBufferMediaStreamProvider implements MediaStreamProvider {
  mediaElementSource: HTMLMediaElement | undefined = undefined;

  constructor(private audioPath: string, private shouldLoop: boolean = false) {}

  async getMediaStream(): Promise<MediaStream> {
    try {
      const resp = await fetch(this.audioPath);
      const bytes = await resp.arrayBuffer();
      const audioData = new TextDecoder('utf8').decode(bytes);
      const audio = new Audio('data:audio/mpeg;base64,' + audioData);
      audio.loop = this.shouldLoop;
      audio.crossOrigin = 'anonymous';
      audio.play();
      this.mediaElementSource = audio;

      // @ts-ignore
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const streamDestination = audioContext.createMediaStreamDestination();
      const mediaElementSource = audioContext.createMediaElementSource(audio);
      mediaElementSource.connect(streamDestination);
      return streamDestination.stream;
    } catch (e) {
      console.log(`Error fetching audio from ${this.audioPath}: ${e}`);
      return Promise.reject(e);
    }
  }

  pause(): void {
    this.mediaElementSource!.pause();
  }

  resume(): void {
    this.mediaElementSource!.play();
  }
}

/**
 * [[SynthesizedStereoMediaStreamProvider]] generates a stereo tone by using 2 `OsciallatorNode`s that
 * produce 2 different frequencies. The output of these 2  nodes is passed through a `ChannelMergerNode` to obtain
 * an audio stream with stereo channels where the left channel contains the samples genrated by one node and the
 * right channel contains samples generated by the other.
 */
export class SynthesizedStereoMediaStreamProvider implements MediaStreamProvider {
  mediaElementSource: HTMLMediaElement | undefined = undefined;

  constructor(private toneHzLeft: number, private toneHzRight: number) {}

  async getMediaStream(): Promise<MediaStream> {
    const audioContext = DefaultDeviceController.getAudioContext();
    const outputNode = audioContext.createMediaStreamDestination();
    const gainNode = audioContext.createGain();
    gainNode.gain.value = 0.1;
    gainNode.connect(outputNode);
    const oscillatorNodeLeft = audioContext.createOscillator();
    oscillatorNodeLeft.frequency.value = this.toneHzLeft;
    const oscillatorNodeRight = audioContext.createOscillator();
    oscillatorNodeRight.frequency.value = this.toneHzRight;
    const mergerNode = audioContext.createChannelMerger(2);
    oscillatorNodeLeft.connect(mergerNode, 0, 0);
    oscillatorNodeRight.connect(mergerNode, 0, 1);
    mergerNode.connect(gainNode);
    oscillatorNodeLeft.start();
    oscillatorNodeRight.start();
    return outputNode.stream;
  }

  pause(): void {
    // No point since synthesized
  }

  resume(): void {
    // No point since synthesized
  }
}

/**
 * [[AudioGainMediaStreamProvider]] wraps another [[MediaStreamProvider]] and applies some amount of audio gain.
 * It will discard any video tracks in the process, use [[MergedMediaStreamProvider]] if needed to recombine.
 */
export class AudioGainMediaStreamProvider implements MediaStreamProvider {
  private context = new AudioContext();

  constructor(private streamProvider: MediaStreamProvider, private gain: number) {}

  async getMediaStream(): Promise<MediaStream> {
    const inputMediaStream = await this.streamProvider.getMediaStream();

    var mediaStreamSource = this.context.createMediaStreamSource(inputMediaStream);
    var destination = this.context.createMediaStreamDestination();
    var gain = this.context.createGain();
    gain.gain.value = this.gain;
    mediaStreamSource.connect(gain);
    gain.connect(destination);

    // Clone video tracks
    for (const videoTrack of inputMediaStream.getVideoTracks()) {
        destination.stream.addTrack(videoTrack);
    }

    return destination.stream;
  }

  pause(): void {
    this.streamProvider.pause();
  }

  resume(): void {
    this.streamProvider.resume();
  }
}

/**
 * [[ScreenShareMediaStreamProvider]] wraps the `MediaStream` returned by a `getUserMediaCall`
 */
export class ScreenShareMediaStreamProvider implements MediaStreamProvider {
  private mediaStream: MediaStream | any = undefined;

  constructor(private framerate: number) {}

  async getMediaStream(): Promise<MediaStream> {
    if (this.mediaStream !== undefined) {
      return Promise.resolve(this.mediaStream);
    }
    // @ts-ignore https://github.com/microsoft/TypeScript/issues/31821
    this.mediaStream = navigator.mediaDevices.getDisplayMedia({
      audio: true,
      video: {
        frameRate: {
          max: this.framerate,
        },
      },
    });
    return this.mediaStream;
  }

  pause(): void {
    // Nothing to pause
  }

  resume(): void {
    // Nothing to resume
  }
}

/**
 * [[FileMediaStreamProvider]] emits a media stream corresponding to audio/video found at the
 * provided URI.
 */
export class FileMediaStreamProvider implements MediaStreamProvider {
  mediaElementSource: HTMLVideoElement | any = undefined;

  constructor(path: string) {
    this.mediaElementSource = document.getElementById('content-share-video') as HTMLVideoElement;
    this.mediaElementSource.src = path;
  }

  async getMediaStream(): Promise<MediaStream> {
    return this.playToStream(this.mediaElementSource);
  }

  private async playToStream(videoFile: HTMLVideoElement): Promise<MediaStream> {
    await videoFile.play();

    if (new DefaultBrowserBehavior().hasFirefoxWebRTC()) {
      // @ts-ignore
      return videoFile.mozCaptureStream();
    }

    // @ts-ignore
    return videoFile.captureStream();
  }

  pause(): void {
    this.mediaElementSource!.pause();
  }

  resume(): void {
    this.mediaElementSource!.play();
  }
}

/**
 * [[VideoTransformDeviceMediaStreamProvider]] emits a media stream corresponding to a [[VideoTransformDevice]]
 */
export class VideoTransformDeviceMediaStreamProvider implements MediaStreamProvider {
  constructor(private streamProvider: MediaStreamProvider, private transformDevice: VideoTransformDevice) {}

  async getMediaStream(): Promise<MediaStream> {
    return this.transformDevice.transformStream(await this.streamProvider.getMediaStream());
  }

  pause(): void {
    this.streamProvider.pause();
  }

  resume(): void {
    this.streamProvider.resume();
  }
}

/**
 * [[MergedMediaStreamProvider]] combines the audio from one [[MediaStreamProvider]] with
 * video from another [[MediaStreamProvider]]
 */
export class MergedMediaStreamProvider implements MediaStreamProvider {
  private outputStream = new MediaStream();

  constructor(private audioStream: MediaStreamProvider, private videoStream: MediaStreamProvider) {}

  async getMediaStream(): Promise<MediaStream> {
    // WARNING: For currently unknown reasons this only works when cloned audio tracks are
    // added first. If added second no audio will be sent.

    for (const videoTrack of (await this.videoStream.getMediaStream()).getVideoTracks()) {
      console.log(`Adding video track ${videoTrack.id} to merged media stream`);
      this.outputStream.addTrack(videoTrack.clone());
    }
    for (const audioTrack of (await this.audioStream.getMediaStream()).getAudioTracks()) {
      console.log(`Adding audio track ${audioTrack.id} to merged media stream`);
      this.outputStream.addTrack(audioTrack.clone());
    }
    return Promise.resolve(this.outputStream);
  }

  pause(): void {
    this.audioStream.pause();
    this.videoStream.pause();
  }

  resume(): void {
    this.audioStream.resume();
    this.videoStream.resume();
  }
}
