import {Inject, Injectable, Pipe, PipeTransform, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from "@angular/common";

@Pipe({
    name: 'dir'
})
@Injectable({
    providedIn: 'root'
})
export class DirPipe implements PipeTransform {
    platformId: Object

    constructor(@Inject(PLATFORM_ID) platformId: Object,  @Inject(DOCUMENT) private document: Document,) {
        this.platformId = platformId
    }

    transform(value: string): string {
        if (isPlatformBrowser(this.platformId)) {
          // below code check and change according to language
          if (document.dir == ""){
            const lang= localStorage.getItem("defaultLanguage") || 'en';
            if (lang == "en"){
              document.dir = 'ltr';
            }
            else if (lang == "ar") {
              document.dir = 'rtl';
            }
          }
            return document.dir
        } else {
            return 'ltr'
        }
    }

}
