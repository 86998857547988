import {ICurrencyModel} from "../../lookups/models/currency";

export interface IPlanModel {
    id: number,
    name: string,
    nameAr: string,
    description: string
    descriptionAr: string
    isRecommended: boolean,
    duration: IPlanDurationModel
    price: number,
    vatAmount: number,
    totalAmount: number,
    currency: ICurrencyModel
    details: IPlanFeatureModel[]
}

export interface IPlanDurationModel {
    id: number,
    name: string,
    nameAr: string,
    count: number
}

export interface IPlanFeatureModel {
    id: number,
    name: string
    nameAr: string
    isVisible: boolean,
    key: string,
    value: string,
}

export enum PlanEnum {
    Basic = 4,
    Silver = 5,
    Gold = 6,
    Platinum = 7,
    YearlySilver = 8,
    YearlyGold = 9,
    YearlyPlatinum = 10,
    TestingPackage = 100,
    RecurringPackage = 101,
    RecurringPackage2 = 102,
}

export enum FeatureSPCompanyWeekEnum {
    NotFeatured = 0,
    Week1 = 1,
    Week2 = 2,
    Week3 = 3,
}

export interface ISubscriptionViewModel {
    subscriptionPackageId: number,
    endDate: Date,
    id: number
    startDate: Date
    packageForId: number
}

export class SubscriptionViewModel implements ISubscriptionViewModel {
    subscriptionPackageId = 0;
    endDate = new Date();
    id: number = 0;
    packageForId: number = 0;
    startDate = new Date();

    constructor(subscriptionPackageId: number) {
        this.subscriptionPackageId = subscriptionPackageId;
    }

}


export interface IDurationSubscriptionModel {
    name: string;
    id: FeatureSPCompanyWeekEnum;
    price: string
}
