import { Component } from '@angular/core';
import {RoutersObject} from "../../../utils/constants/routers-object";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  homeRoute: string = RoutersObject.home;
}
