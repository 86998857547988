import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { catchError, debounceTime, map, Observable, of, Subscription, switchMap, tap } from "rxjs";
import { ILookupsModel } from "../../../../../core/models/lookups";
import { HttpHeaders } from "@angular/common/http";
import { default as apiLinks } from "../../../../../../../../app/utils/constants/api-links.json";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { IPaginationWithDataModel } from "../../../../../core/models/pagination";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { ApiService } from "../../../../../../../service/api.service";
import { DirectionService } from "../../../../../core/services/direction.service";
import { CommonConfigService } from "../../../../../../../utils/common/common-config.service";
import {environment} from "../../../../../../../../environments/environment";
import { NewsTitleEncodedService } from '../../../../../../../../app/service/newsTitleEncoded.service';

@Component({
  selector: 'app-all-companies-lookups',
  templateUrl: './all-companies-lookups.component.html',
  styleUrls: ['./all-companies-lookups.component.scss']
})
export class AllCompaniesLookupsComponent implements OnInit {
  @Input() placeholder: string = ''
  @Input() label: string = ''
  @Input() id: string = ''
  @Input() data?: string
  @Input() control: FormControl = new FormControl();
  @Input() showSearchDropdownClicked: boolean = false;
  @Input() variant: 'custom-select-search' | 'lookups' = 'lookups';
  private url = apiLinks.api.mainService.company.searchAllCompanies;
  @Input() removeValue: Observable<void> = new Observable<void>();
  searchControl = new FormControl();
  search?: string;
  suggestions$?: Observable<ILookupsModel[]>;
  errorMessage?: string;
  companyData: any = {};
  inputExists: boolean = false;
  fileUrl: string = '';

  companyTypeMapping: any = {
      'listedCompanies': {
          title: 'Listed Companies',
          imgSrc: '../../../../../../../../assets/images/svg/listed-companies.svg',
      },
      'nonListedCompanies': {
          title: 'Non-Listed Companies',
          imgSrc: '../../../../../../../../assets/images/svg/non-listed-companies.svg',
      },
      'serviceProviders': {
          title: 'Service Provider Profile',
          imgSrc: '../../../../../../../../assets/images/svg/sp-profile.svg',
      }
  };

  constructor(private http: ApiService, private router: Router, public directionService: DirectionService, private commonService: CommonConfigService, private newsTitleEncodedService: NewsTitleEncodedService,) {
      this.fileUrl = environment.fileUrl;
  }

  ngOnChanges() {
    if(this.showSearchDropdownClicked === false) {
      this.searchControl.reset();
    }
  }

  selectCompany(company: any, companyType: string){
      const companyId = company.companyId?.toString() ?? '';
      const companyName = company.companyName ?? '';
      if (companyType ==='serviceProviders') {
        this.router.navigateByUrl('sp/sp/' + this.newsTitleEncodedService.getEncodedTitle(companyId) +'/'+ this.newsTitleEncodedService.getEncodedTitle(companyName));
      } else {
        this.router.navigateByUrl('company-profile-detail-view/' + this.newsTitleEncodedService.getEncodedTitle(company.companyName +'-'+ company.companyId?.toString()));
      }
  }

  onImageError(event: Event) {
      const target = event.target as HTMLImageElement;
      target.src = '../../../../../../../../assets/images/default-image.png';
  }

  routeToRegisterForm() {
    this.router.navigateByUrl('/company/register')
  }

  routeToSP() {
    this.router.navigateByUrl('/sp/register/sp')
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
        debounceTime(500), // Wait for 500ms after the last keystroke
        switchMap(query => query ? this.performSearch(query) : of([])) // If query exists, call search; otherwise return empty array
    ).subscribe(
        data => {
            this.suggestions$ = of(data); // Update suggestions with the API results
        },
        error => {
            console.error('API Error:', error);
            this.errorMessage = 'Something went wrong'; // Handle the error
        }
    );
  }

  performSearch(query: string): Observable<ILookupsModel[]> {
      this.inputExists = query ? query.trim().length > 0: false;
      if (query) {
          const currentLanguage = this.commonService.currentLanguage();
          const headers = new HttpHeaders({
              'Accept-Language': currentLanguage
          });
          return this.http.postT<IPaginationWithDataModel<ILookupsModel>>(
              this.url, {
              filters: this.getFilters(query),
              page: 0,
              size: 5,
              sorts: this.getSortFilters()
          },
          { headers }
          ).pipe(
              map((response) => {
                  this.companyData = response.data || [];
                  return this.companyData;
              }),
              tap({
                  error: (err) => {
                      console.error('API Error:', err); // Log the error details in the console
                      this.errorMessage = err?.message || 'Something went wrong'; // Set error message
                  }
              }),
              catchError(err => {
                  console.error('API Error:', err);
                  this.errorMessage = err?.message || 'Something went wrong';
                  return of([] as ILookupsModel[]); // Return an empty array of the correct type
              })
          );
      } else {
          return of([] as ILookupsModel[]); // Return an empty array of the correct type
      }
  }

  getFilters(query: string) {
      return [
          {
              key: 'companyName',
              operation: ':',
              value: query
          }
      ]
  }

  getSortFilters() {
      return [
          {
              key: 'id',
              direction: 'DESC'
          }
      ]
  }
}