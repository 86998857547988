import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {LoaderComponent} from "./components/loader/loader.component";
import {SocialComponent} from "./components/social/social.component";
import {ButtonComponent} from './components/button/button.component';
import {InputComponent} from './components/input/input.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FilterDateRangeComponent} from './components/filter-date-range/filter-date-range.component';
import {DatepickerComponent} from './components/datepicker/datepicker.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {PaginationComponent} from './components/pagination/pagination.component';
import {PaginationModule} from "ngx-bootstrap/pagination";
import {TagComponent} from "./components/tag/tag.component";
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {RouterModule} from "@angular/router";
import {SelectComponent} from "./components/select/select.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {TranslateModule} from "@ngx-translate/core";
import {ButtonSortComponent} from './components/button-sort/button-sort.component';
import {DatepickerButtonComponent} from './components/datepicker-button/datepicker-button.component';
import {CoreModule} from "../../core/core.module";
import {ShareComponent} from './components/share/share.component';
import {ShareButtonsModule} from "ngx-sharebuttons/buttons";
import {ShareIconsModule} from "ngx-sharebuttons/icons";
import {TextareaComponent} from "./components/textarea/textarea.component";
import {PasswordComponent} from "./components/password/password.component";
import {UploadImageComponent} from "./components/upload-image/upload-image.component";
import {CheckboxComponent} from "./components/checkbox/checkbox.component";
import {StepTabComponent} from "./components/step-tab/step-tab.component";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {FileUploadModule} from "@iplab/ngx-file-upload";
import {UploadFileComponent} from "./components/upload-file/upload-file.component";
import {UploadAvatarComponent} from "./components/upload-avatar/upload-avatar.component";

@NgModule({
    declarations: [
        LoaderComponent,
        SocialComponent,
        ButtonComponent,
        InputComponent,
        FilterDateRangeComponent,
        DatepickerComponent,
        PaginationComponent,
        TagComponent,
        BreadcrumbComponent,
        SelectComponent,
        ButtonSortComponent,
        DatepickerButtonComponent,
        ShareComponent,
        UploadImageComponent,
        CheckboxComponent,
        StepTabComponent,
        PasswordComponent,
        TextareaComponent,
        UploadFileComponent,
        UploadAvatarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        PaginationModule,
        RouterModule,
        NgSelectModule,
        TranslateModule,
        CoreModule,
        ShareButtonsModule.withConfig({
            debug: true,
        }),
        ShareIconsModule,
        FileUploadModule,
        TypeaheadModule.forRoot(),
        TabsModule,
        NgOptimizedImage

    ],
    exports: [
        SocialComponent,
        ButtonComponent,
        InputComponent,
        DatepickerComponent,
        FilterDateRangeComponent,
        PaginationComponent,
        TagComponent,
        BreadcrumbComponent,
        DatepickerButtonComponent,
        SelectComponent,
        ButtonSortComponent,
        ShareComponent,
        UploadImageComponent,
        CheckboxComponent,
        StepTabComponent,
        PasswordComponent,
        TextareaComponent,
        LoaderComponent,
        UploadFileComponent,
        UploadAvatarComponent
    ],
})
export class UiModule {
}
