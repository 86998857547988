import { Component } from '@angular/core';
import {RoutersObject} from "../../../utils/constants/routers-object";

@Component({
  selector: 'app-success-reset',
  templateUrl: './success-reset.component.html',
  styleUrls: ['./success-reset.component.scss']
})
export class SuccessResetComponent {

  routes = RoutersObject;
}
