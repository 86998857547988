import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ComparePasswordsValidator {
  public match(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn {
    return (controls: AbstractControl): ValidationErrors | null => {
      const password = controls.get(passwordControlName);
      const confirmPassword = controls.get(confirmPasswordControlName);

      if (password && confirmPassword && password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ matching: true });
      } else {
        confirmPassword?.setErrors(null);
      }
      return null;
    };
  }
}
