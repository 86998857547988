import { Component } from '@angular/core';
import { SplashScreenService } from '../../../service/splash-screen.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent {
  showSplash = true;

  constructor(private splashService : SplashScreenService){
     this.splashService._splashScreenOb$.subscribe(value => this.showSplash = value as boolean);
  }


}
