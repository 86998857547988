// scroll.service.ts
import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {MatDialog} from "@angular/material/dialog";
import {
  NewsletterSubscriptionDialogComponent
} from "../../shared/dialogs/newsletter-subscription-dialog/newsletter-subscription-dialog.component";
import {ApiService} from "../api.service";
import {default as apiLinks} from "src/app/utils/constants/api-links.json";
import {NewsletterModel} from "./models/newsletter.model";
import {AuthService} from "../auth/auth.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AdsContact} from "../../pages/public/media-hub/features/home/models/ads-contact";

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  private scroll$!: Observable<number>;
  subscriptionCallOnAction = false;
  isUserSubscribedNewsletter = false;

  constructor(@Inject(DOCUMENT) private document: Document,
              public matDialog: MatDialog,
              private apiService: ApiService,
              private jwtHelper: JwtHelperService,
              private authService: AuthService) {
    this.setupScrollObservable();
  }

  public subscribeNewsLetter(userData: NewsletterModel): Observable<any> {
    return this.apiService.formPost(apiLinks.api.mainService.newsLetter.saveSubscription, userData);
  }

  public adsContact(userData: AdsContact): Observable<any> {
    return this.apiService.formPost(apiLinks.api.mainService.adsContact.saveContact, userData);
  }
  private setupScrollObservable() {
    this.scroll$ = fromEvent(window, 'scroll').pipe(
      debounceTime(200),
      map(() => this.document.defaultView?.scrollY || 0)
    );
  }

  getScrollObservable(): Observable<number> {
    return this.scroll$;
  }

  isScrolled25Percent(): boolean {
    const scrollY = this.document.defaultView?.scrollY || 0;
    const windowHeight = this.document.defaultView?.innerHeight || 0;
    const documentHeight = this.document.documentElement.scrollHeight;

    // Check if the user has scrolled 25% vertically
    return scrollY > 0.25 * (documentHeight - windowHeight);
  }

  showNewsLetterDialog(subscriptionCallOnAction: boolean = false) {

    this.subscriptionCallOnAction = subscriptionCallOnAction ?? false;
    let newsLetterPopupClosed = sessionStorage.getItem('newsLetterPopupClosed') == 'true';

    if (!this.authService.checkIfUserIsAuthenticated) {
      if (this.isScrolled25Percent() && !newsLetterPopupClosed && !this.subscriptionCallOnAction) {
        this.matDialog.open(NewsletterSubscriptionDialogComponent)
      } else if (this.subscriptionCallOnAction) {
        this.matDialog.open(NewsletterSubscriptionDialogComponent)
      }
    }
    else if (this.authService.checkIfUserIsAuthenticated) {
      const token = this.authService.authToken;
      let user = token ? this.jwtHelper.decodeToken(token) : '';
      if (!user.isSubscribed) {
        user.isSubscribed = this.isUserSubscribedNewsletter;
      }
      if ((this.isScrolled25Percent() && !newsLetterPopupClosed) && (this.isScrolled25Percent() && !user.isSubscribed)) {
        this.matDialog.open(NewsletterSubscriptionDialogComponent)
      }
      else if (this.subscriptionCallOnAction) {
        this.matDialog.open(NewsletterSubscriptionDialogComponent)
      }
    }
  }
}
