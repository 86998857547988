export const environment = {
  production: true,
  apiUrl: "https://app.sharikatmubasher.com",
  mixDataUrl: "https://data.feedgfm.com/mix2/ClientServiceProvider?",
  dataAccessApiUrl: "https://app.sharikatmubasher.com/data-service",
  fileUrl: "https://assets.sharikatmubasher.com/",
  nodeAwsChime: 'https://app.sharikatmubasher.com/meeting-service',
  financialHighlightsUrl: "https://ir.directfn.com/smfinancial/ir/smfinancial/en/financial/sm/",
  financialHighlightsUrlAr: "https://ir.directfn.com/smfinancial/ir/smfinancial/ar/financial/sm/",
  pk: 'pk_live_51NdRLFLmNFm0dQ1rfuA5WddDPsgg80xXOKPA04rRy5CAz3bDKWv6SeyghUGHe11VPLdmAsjxYIwU70fKcXu6bzw8007FScb6PZ',
  fileSize : 20480000,
  fileSizeStr: "20 MB",
  allowedFileFormat : ".pdf,.png,.jpeg,.jpg",
  allowedImageFormats : ".png,.jpeg,.jpg",
  allowedFileContentType : "application/pdf,image/png,image/jpeg,image/jpg",
  arVersion: 'https://sharikatmubasher.com',
  enVersion: 'https://en.sharikatmubasher.com',
  defaultCountry: 'SA'
};
