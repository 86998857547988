<section class="registration-wizard submission-page"
         style="background-image: url('./assets/images/registration-shareholder.png')"
         xmlns="http://www.w3.org/1999/html">
  <div class="container">

    <div class="submission-card">
      <div class="success-icon">
        <img src="././assets/images/icons/success-submission.svg" alt="">
        <h3>{{ 'successfulChanged' | translate }}</h3>
      </div>
      <div class="content">
        <h4>
          {{ 'passwordChanged' | translate }}
        </h4>
        <p class="body-3 ">
          {{ 'loginWithNewPass' | translate }}
        </p>
      </div>
      <div class="action text-center">
        <a [routerLink]="[routes.login]" class="anchor filled">{{ 'login' | translate }}</a>
      </div>
    </div>
  </div>


</section>
