import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import {default as apiLinks} from "src/app/utils/constants/api-links.json";

@Component({
  selector: 'app-email-comfirmation',
  templateUrl: './email-comfirmation.component.html',
  styleUrls: ['./email-comfirmation.component.scss']
})
export class EmailComfirmationComponent implements OnInit{
  constructor(private apiService: ApiService, private activatedRoute : ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe(
      (paramsMap : any) => {
            this.apiService.post(apiLinks.api.mainService.registration.verifyEmailVarificationToken,
            {
              verifyToken: paramsMap.params.token
            }).subscribe(res => {


            })
      }
    )
  }
  ngOnInit(): void {

  }



}
