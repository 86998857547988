import {ElementRef, Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {startCase} from "lodash";
import {LookupService} from "../../service/lookup/lookup.service";
import {Observable, Subscriber} from "rxjs";
import {isPlatformBrowser} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class CommonConfigService {

  LANG_EN = "en";
  LANG_AR = "ar";
  isBrowser: boolean = true;

  constructor(
    private lookupService: LookupService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /**
   * Convert Search Criteria Objects
   */
  public currentLanguage(): string {

    let _lang = localStorage.getItem('defaultLanguage');

    if (_lang == null || _lang === '') {
      _lang = this.LANG_EN;
    }

    return _lang;
  }

  public multiSelectLanguageChange(columnName: string): string {

    return this.currentLanguage() != 'en' ? columnName + startCase(this.currentLanguage()) : columnName;
  }

  public getCountryNameFromCountryCode(countryCode: string = ''): string {
    if (this.lookupService.getAllCountries()?.filter(country => country.countryCode === countryCode).length > 0) {
      return this.currentLanguage() == 'en' ? this.lookupService.getAllCountries()?.filter(country => country.countryCode === countryCode)[0]?.countryName ?? '' :
        this.lookupService.getAllCountries()?.filter(country => country.countryCode === countryCode)[0]?.countryNameAr ?? '';
    } else {
      return countryCode;
    }
  }
  public getCountryNamesFromCountryCodesStringOrArray(countryCodes: string | string[] = ''): string | string[] {
    if(typeof countryCodes === "string" && countryCodes.includes(',')) {
      let arrayOfCodes: string[];
       arrayOfCodes = countryCodes.replace(/"/g, '').split(',');
      return arrayOfCodes.map(code => this.getCountryNameFromCountryCode(code))?.join(', ');
    }
    if (Array.isArray(countryCodes)) {
      return countryCodes.map(code => this.getCountryNameFromCountryCode(code));
    } else {
      return this.getCountryNameFromCountryCode(countryCodes);
    }
  }

  public getTitle(did: any, titleModelList: any[]):string{
    let title:string='';
    titleModelList.forEach((key:any)=>{
      if(key.id==did){
        title=this.currentLanguage() == 'en'? key.titleName : key.titleNameAr
      }
    });
    return title;
  }

  public designation(did:any,designationModelList: any[]):string{
    let desig:string='';
    designationModelList.forEach((key:any)=>{
      if(key.id==did){
        desig=this.currentLanguage() == 'en' ? key.designationName : key.designationNameAr
      }
    });
    return desig;
  }

  /**
   * Get country Flag icons.
   */
  public getFlagIcons(countryCode: string = 'SA') {
    if (countryCode) {
      return `./assets/flags/countries-flag/${countryCode?.toLowerCase()}.svg`;
    } else {
      // return './assets/images/icons/ksa.svg';
      return '';
    }
  }

  /**
   * Convert File to Base 64.
   */
  convertToBase64(file: File): Observable<any> {
    return new Observable((subscriber: Subscriber<any>) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        subscriber.next(fileReader.result);
        subscriber.complete();
      }
      fileReader.onerror = (error) => {
        subscriber.error(error);
        subscriber.complete();
      }
    });
  }

  ellipsisText(str: string, n: number): string {
    if (str.length <= n) return str;
    return str.slice(0, n) + '...';
  }

  handleMissingImage(image: HTMLImageElement, defaultImage:string) {
    image.src = defaultImage;
  }

  fixeAlignmentOfScaledElement(elementRef:ElementRef, scale: number) {
    let height = elementRef.nativeElement.offsetHeight
    let width = elementRef.nativeElement.offsetWidth
    if (window.innerWidth >= 1200) {
      elementRef.nativeElement.style.margin = '0 auto calc(-' + height + 'px * (1 - ' + scale + '))'
    }
  }
}
