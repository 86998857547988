import {Component} from '@angular/core';
import {RoutersObject} from "../../../utils/constants/routers-object";
import {AuthService} from "../../../service/auth/auth.service";
import {CommonConfigService} from "../../../utils/common/common-config.service";
import {NewsletterService} from "../../../service/newsletter/newsletter.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  routes = RoutersObject;
  subscriptionCallOnAction : boolean = false;
  isAuthenticated = false

  constructor(private newsletterService: NewsletterService, private authService: AuthService,public commonConfigService: CommonConfigService) {
    this.isAuthenticated = this.authService.checkIfUserIsAuthenticated
  }

  openNewsletterPopupModal() {
    this.subscriptionCallOnAction = true
    this.newsletterService.showNewsLetterDialog(this.subscriptionCallOnAction);
  }
}
