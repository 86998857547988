<footer>
  <div class="container">
    <div class="d-lg-flex justify-content-between main-wrapper">
      <div class="footer-logo">
        <ng-container *ngIf="commonConfigService.currentLanguage() == 'en'">
          <img alt="" src="././assets/images/sm-english.svg"/>
        </ng-container>
        <ng-container *ngIf="commonConfigService.currentLanguage() == 'ar'">
          <img alt="logo-ar" height="46" src="assets/images/sm-arabic.svg" width="149"/>
        </ng-container>
        <p class="footer-desc"> {{ 'footerDesc' | translate }}</p>
      </div>
      <div class="wrapper" *ngIf="!isAuthenticated">
        <ul class="footer-navbar">
          <li class="nav-item">
            <a
              class="nav-link "
              aria-current="page"
              [routerLink]="[routes.home]"
            >{{"Home" | translate }}</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link "
               [routerLink]="[routes.companies]">{{"Companies" | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
               [routerLink]="[routes.investors]">{{"Investors" | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
               [routerLink]="[routes.finTech]">{{"finTechHub" | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
               [routerLink]="[routes.opportunities]">{{"Opportunities" | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " [routerLink]="[routes.sharikatMubasherNews]">{{"News" | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
               [routerLink]="[routes.contactUs]">{{"ContactUs" | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link "
               [routerLink]="[routes.sitemap]">{{"Sitemap" | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="mid d-md-flex justify-content-end">
      
      <div class="social-icons footer-social-icons d-flex">
        <a class="icon fb-icon" href="https://www.facebook.com/SharikatMubasher?_rdc=1&_rdr" target="_blank" >
            <i class="fa-brands fa-facebook-f"></i>
        </a>
        <a class="icon tw-icon" href="https://twitter.com/MSharikat" target="_blank">
            <i class="fa-brands fa-twitter"></i>
        </a>
        <a class="icon lin-icon" href="https://www.linkedin.com/company/sharikatmubasher" target="_blank">
            <i class="fa-brands fa-linkedin-in"></i>
        </a>
        <a class="icon insta-icon" href="https://www.instagram.com/sharikatmubasher/" target="_blank">
            <i class="fa-brands fa-instagram"></i>
        </a>
      </div>
    </div>
    <div class="mid d-md-flex justify-content-between">
      <button class="newsletter-subscribe-btn" (click)="openNewsletterPopupModal()">{{'newsLetter.widgetTitle' | translate}}</button>
    </div>
    <div class="lower d-md-flex justify-content-between">
      <span>{{ 'copyRight' | translate }}</span>
    </div>
  </div>
</footer>
