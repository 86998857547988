import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../../service/auth/auth.service";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {AuthResponseModel} from "../../../service/auth/model/auth-response.model";
import {Observable} from "rxjs";
import {RoutersObject} from "../../../utils/constants/routers-object";
import {LanguageHandlerService} from "../../../service/language/language-handler.service";
import {CommonConfigService} from "../../../utils/common/common-config.service";
import {cssObject} from "../../../utils/constants/css-object";
import {MetaTagsService} from "../../../utils/common/meta-tags.service";
import {default as pagesTitle} from "src/app/utils/constants/titles.json"

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewChecked {

  routes = RoutersObject;
  errorMessageOfServer: string = '';
  loginForm!: FormGroup;

  isSubmitting: boolean = false;

  submitButtonText: string = 'Login';
  errorMsg!: string;
  currentLang: string = "";
  @ViewChild('login') login = {} as ElementRef;

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private langHandler: LanguageHandlerService,
    public commonConfigService: CommonConfigService,
    private metaTagsService: MetaTagsService
  ) {

  }


  public onLogin(): void {
    // if (this.loginForm?.invalid) {
    //   return;
    // }

    this.isSubmitting = true;
    this.submitButtonText = 'loading';

    if (this.loginForm?.invalid) {
      this.loginForm.markAllAsTouched();
      this.isSubmitting = false;
      this.submitButtonText = 'Login';
      return;
    }

    let authObs: Observable<AuthResponseModel>;

    authObs = this.authService.login(this.loginForm?.value);

    authObs.subscribe({
      next: (resData) => {
        this.router.navigate([this.routes.dashboard]).then();
      },
      error: (errorMessage) => {
        this.errorMessageOfServer = errorMessage;
        this.errorMsg = "validationMsg.inValidCredentials";
        this.isSubmitting = false;
        this.submitButtonText = 'Login';
      },
      complete: () => {
        this.loginForm?.reset();
        this.isSubmitting = false;
        this.submitButtonText = 'Login';
      }
    });
  }

  ngOnInit(): void {
    this.metaTagsService.updateTitle(this.commonConfigService.currentLanguage() == "en" ? pagesTitle.titles.login.en : pagesTitle.titles.login.ar);
    if (this.authService.user.value) {
      this.router.navigate([this.routes.dashboard]).then();
    }
    this.loginForm = this.loginFormGroup();
  }

  ngAfterViewChecked() {
    this.commonConfigService.fixeAlignmentOfScaledElement(this.login, cssObject.scale);
  }

  // ngDoCheck() {
  //   this.langHandler.langSubject.subscribe(
  //     (res: any) => this.currentLang = res
  //   );
  //   this.currentLang = this.langHandler.lang;
  //
  // }

  private loginFormGroup(): FormGroup {
    return this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^[^\u0600-\u06FF]+$/)]]
    })
  }

  public shouldShowError(input?: any, validation?: { type: string; message: string }): string {
    if (input?.hasError(validation?.type) && (input?.dirty || input?.touched)) {
      return 'block';
    } else {
      return 'none';
    }
  }


  checkValidations(formGroup: FormGroup): void {
    if (formGroup.invalid) {
      formGroup.markAllAsTouched();
    }
  }

  public loginFormValidationMessages = {
    username: [
      {type: 'required', message: 'validationMsg.emailRequired'},
      {type: 'email', message: 'validationMsg.validEmail'},
      {type: 'pattern', message: 'validationMsg.englishAlphabetsOnly'}
    ],
    password: [
      {type: 'required', message: 'validationMsg.passwordRequired'},
      {type: 'minLength', message: 'validationMsg.minPasswordLength'},
      {type: 'pattern', message: 'validationMsg.englishAlphabetsOnly'}
    ],
  }

}
