import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AWSChimeService {
private baseAwsChime: string = '';
constructor(private http : ApiService) {
  this.baseAwsChime = environment.nodeAwsChime;
 }

creatingMeeting(){
  return this.http.getChime(this._createMeeting());
}

getMeetingById(mid : string){
  return this.http.getChime(this._getMeeting(mid));
}

async createAttendee(mid: string){
  return await this.http.getChime(this._createAttendee(mid)).toPromise();
}

_createMeeting(){
  return this.baseAwsChime.concat('/createMeetings');
}

_getMeeting(mid: string){
  return this.baseAwsChime.concat('/getMeetings','?m=', mid);
}

_createAttendee(mid: string){
  return this.baseAwsChime.concat('/createAttendee','?m=', mid);
}
}
