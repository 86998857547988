import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '../../dialogs/change-password-dialog/change-password-dialog.component'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  constructor(public matDialog: MatDialog) {
  }

  ngOnInit(): void {
    const dialogRef = this.matDialog.open(ChangePasswordDialogComponent, {width:'35rem', disableClose: true, data: {isFirstTime : true} });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {

      }
    });
  }
}
