import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {ITokenPayloadModel} from "../../features/sp/auth/models/token";
import {constants} from "../utils/constants";
import jwt_decode from "jwt-decode";
import {Router} from "@angular/router";
import {href} from "../utils/href";
import {ISubscriptionViewModel, PlanEnum} from "../../shared/sp/subscription-plans/models/plan";
import {isEmpty} from "lodash";
import {BehaviorSubject} from "rxjs";
import {UserType} from "../models/user";


@Injectable({
    providedIn: 'root'
})
export class UserService {
    userType: UserType = 'guest';
    id?: number;
    data?: ITokenPayloadModel
    platformId: Object;
    imageUrl?: string;
    token: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    userType$: BehaviorSubject<UserType> = new BehaviorSubject<UserType>("guest");

    constructor(private cookieService: CookieService,
                private router: Router,
                @Inject(PLATFORM_ID) platformId: Object,
    ) {
        this.platformId = platformId;
        this.token.subscribe({
            next: (value) => {
                if (value) {
                    this.setAuth(value)
                } else {
                    const tokenCookie = cookieService.get(constants.tokenName);
                    if (tokenCookie) {
                        this.setAuth(tokenCookie)
                    } else {
                        this.userType$.next('guest')
                    }
                }

            }
        })

        this.userType$.subscribe({
            next: (value) => {
                this.userType = value;
            }
        })

    }

    logout() {
        this.cookieService.delete(constants.tokenName, '/');
        this.userType$.next('guest');
        this.router.navigateByUrl(href.login);
    }

    isOwner(id: number): boolean {
        if (this.data) {
            if (this.id == id) {
                return true;
            }
        }
        return false
    }

    checkUserSubscription(data: ISubscriptionViewModel[]) {
        if (!isEmpty(data)) {
            let count = 0;
            data.map((item) => {
                if (item.subscriptionPackageId in PlanEnum) {
                    count = count + 1;
                }
            })
            if (count < 1) {
                this.cookieService.set(constants.isSpRegister, 'true', 365, '/')
            }
        } else {
            this.cookieService.set(constants.isSpRegister, 'true', 365, '/')
        }
    }

    setAuth(token: string) {
        const tokenPayload: ITokenPayloadModel = jwt_decode(token);
        if (tokenPayload.auth == 'ROLE_SERVICE_PROVIDER') {
            this.userType$.next('sp')
        } else {
            this.userType$.next('buyer')
        }
        this.data = tokenPayload
    }
}
