import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RoutersObject} from "../../../utils/constants/routers-object";
import {ForgetPasswordRequestService} from "../../../service/auth/forget-password-request.service";
import {AuthService} from "../../../service/auth/auth.service";
import {UserService} from "../../../pages/sp-buyer-users/core/services/user.service";
import {UserTypesObject} from "../../../utils/constants/user-types-object";
import {JwtHelperService} from "@auth0/angular-jwt";

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent {
  updatePasswordOnResetRequestForm!: FormGroup;
  routes = RoutersObject;
  isSubmitting: boolean = false;
  submitButtonText: string = 'submit';
  errorMsg!: string;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public offerShares: any,
    @Inject(MAT_DIALOG_DATA) public data: {isFirstTime : boolean},
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private forgetPasswordRequestService: ForgetPasswordRequestService,
    private router: Router,
    private formBuilder: FormBuilder,
    private jwtHelper: JwtHelperService,
  ) {
    this.updatePasswordOnResetRequestForm = this.updateResetRequestFormGroup();
    //this.offerShares.update == "update" ? this.submitButtonText = "Update" : this.submitButtonText = "submit";
  }

  close(): void {
    this.dialogRef.close();
  }

  private updateResetRequestFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: [undefined],
      currentPassword: ['', Validators.compose([Validators.required ,Validators.pattern(/^[^\u0600-\u06FF]+$/)])],
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(50), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$"), Validators.pattern(/^[^\u0600-\u06FF]+$/)])],
      confirmNewPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(50), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$"), Validators.pattern(/^[^\u0600-\u06FF]+$/)])],
    }, {validators: [this.validateAreEqual, this.validateAreEqualSame]})
  }

  public validateAreEqual(c: AbstractControl): { notSame: boolean } | null {
    return c.value.newPassword === c.value.confirmNewPassword ? null : {notSame: true};
  }

  public validateAreEqualSame(c: AbstractControl): { notOldSame: boolean } | null {
    return c.value.currentPassword !== c.value.newPassword ? null : {notOldSame: true};
  }

  public shouldShowError(input?: any, validation?: { type: string; message: string }): string {
    if (input?.hasError(validation?.type) && (input?.dirty || input?.touched)) {
      return 'block';
    } else {
      return 'none';
    }
  }

  public showFormError(input?: any, validation?: { type: string; message: string }): string {
    if (input?.hasError(validation?.type) && (input?.dirty || input?.touched)) {
      return 'block';
    } else {
      return 'none';
    }
  }

  public updatePasswordOnResetRequestFormValidationMessages = {
    currentPassword: [
      {type: 'required', message: 'New Password is required'},
      {type: 'pattern', message: 'validationMsg.englishAlphabetsOnly'},
    ],
    newPassword: [
      {type: 'required', message: 'validationMsg.newPassword'},
      {type: 'minlength', message: 'validationMsg.minPasswordLength'},
      {type: 'maxlength', message: 'validationMsg.maxPasswordLength'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneNumber'},
      {type: 'notOldSame', message: 'validationMsg.matchPassword'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneUppercase'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneSpecialCharacter'},
      {type: 'pattern', message: 'validationMsg.englishAlphabetsOnly'},
    ],
    confirmNewPassword: [
      {type: 'required', message: 'validationMsg.newPassword'},
      {type: 'minlength', message: 'validationMsg.minPasswordLength'},
      {type: 'maxlength', message: 'validationMsg.maxPasswordLength'},
      {type: 'notSame', message: 'validationMsg.matchPassword'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneNumber'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneUppercase'},
      {type: 'pattern', message: 'validationMsg.PasswordContainOneSpecialCharacter'},
      {type: 'pattern', message: 'validationMsg.englishAlphabetsOnly'},
    ]
  }

  onSubmitResetPassword() {
    this.isSubmitting = true;
    this.submitButtonText = 'loading';

    if (this.updatePasswordOnResetRequestForm?.invalid) {
      this.updatePasswordOnResetRequestForm.markAllAsTouched();
      this.isSubmitting = false;
      this.offerShares.update == "update" ? this.submitButtonText = "Update" : this.submitButtonText = "submit";
      return;
    }

    const token = this.authService.authToken;
    let tokenDetails = token ? this.jwtHelper.decodeToken(token) : '';

    this.forgetPasswordRequestService.updateResetPassword(this.updatePasswordOnResetRequestForm.value).subscribe({
      next: () => {
      },
      error: (errorMessage) => {
        this.errorMsg = "validationMsg.matchPassword";
        this.isSubmitting = false;
        this.offerShares.update == "update" ? this.submitButtonText = "Update" : this.submitButtonText = "submit";
        tokenDetails.type  == UserTypesObject.buyerUser ? this.router.navigateByUrl('sp/public/top-recommendations') : this.authService.logout();
        this.close();
      },
      complete: () => {
        this.updatePasswordOnResetRequestForm?.reset();
        this.isSubmitting = false;
        this.offerShares.update == "update" ? this.submitButtonText = "Update" : this.submitButtonText = "submit";
        tokenDetails.type  == UserTypesObject.buyerUser ? this.router.navigateByUrl('sp/public/top-recommendations') : this.authService.logout();
        this.close();
      }
    })

  }

  checkValidations(formGroup: FormGroup): void {
    if (formGroup.invalid) {
      formGroup.markAllAsTouched();
    }
  }
}
