import {Component, ElementRef, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import {RoutersObject} from "../../../../utils/constants/routers-object";

import {TranslateService} from '@ngx-translate/core';
import {LanguageHandlerService} from "../../../../service/language/language-handler.service";
import {Router} from '@angular/router';
import {CommonConfigService} from "../../../../utils/common/common-config.service";
import { environment } from '../../../../../environments/environment'
import { NewsTitleEncodedService } from 'src/app/service/newsTitleEncoded.service';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent {
  platformId!: Object;
  routes = RoutersObject;
  activeTab = '';
  toggleMenu = false
  logoChangeLang: any = 'en';
  arLangCode = 'ar';
  showDropDown: boolean = false;
  showMediaDropdown: boolean = false;
  showLangDropdown: any;
  showCompanyDropdown: boolean = false;
  showSearchDropdown: boolean = false;
  showLangBtn = true;

  constructor(
    public translate: TranslateService,
    private langHandler: LanguageHandlerService,
    // Media-Hub Integration
    @Inject(PLATFORM_ID) platformId: Object,
    public router: Router,
    private newsTitleEncodedService: NewsTitleEncodedService,
    public commonService: CommonConfigService,
    private eRef: ElementRef
  ) {

    // Media-Hub Integration
    this.platformId = platformId;
    // translate.use(this.cookieService.get('mcLang') || 'en');
    translate.use(localStorage.getItem("defaultLanguage") || 'en'); // changed this from above line
    // this.changeDir(this.cookieService.get('mcLang') || 'en');

  }

  ngOnInit(): void {
    this.translate.addLangs(['en', 'ar']);
    const lang = localStorage.getItem("defaultLanguage") || 'en';
    this.logoChangeLang = localStorage.getItem("defaultLanguage") || 'en';
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.langHandler.langSubject.subscribe(
      (res: any) => {
        this.translate.setDefaultLang(res);
        this.translate.use(res);
        this.logoChangeLang = res;
        // this.document.location.reload();
      }
    );
  }

  _toggleMenu() {
    this.toggleMenu = !this.toggleMenu;
    this.showSearchDropdown = false;
  }

  onCompanyWebIconClicked(){
    this.showSearchDropdown = !this.showSearchDropdown;
  }

  // HostListener to listen for document clicks
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const target = event.target as HTMLElement;
    
    // Check if the click is outside the dropdown
    if (!this.eRef.nativeElement.contains(target)) {
      this.showSearchDropdown = false;
    }
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
    this._toggleMenu();
  }

  langChange(lang: string) {
    // Media-Hub Integration
    if (lang != this.translate.currentLang) {
        const url = lang === this.langHandler.arLangCode ? environment.arVersion : environment.enVersion;
        window.location.href = url;
    }
  }

  countryChange(countryCode: string) {
    if (countryCode != localStorage.getItem("defaultCountry")) {
      localStorage.setItem("defaultCountry", countryCode);
      window.location.reload();
  }
  }


  show = false;

  public toggleField() {
    this.show = !this.show;
  }

  // Media-Hub Integration change dir Method.
  // changeDir(language: string) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const htmlTag = document.getElementsByTagName("html")[0] as HTMLHtmlElement;
  //     const bodyTag = document.getElementsByTagName("body")[0] as HTMLBodyElement;
  //     htmlTag.dir = language === "ar" ? "rtl" : "ltr";
  //     htmlTag.lang = language;
  //     bodyTag.className = language === "ar" ? "rtl" : "ltr";
  //   }
  // }
  OnIconClicked() {
    this.showDropDown = !this.showDropDown;
    this.showMediaDropdown = false;
    this.showLangDropdown = false;
  }

  onMediaClicked() {
    this.showMediaDropdown = !this.showMediaDropdown
    this.showDropDown = false;
    this.showLangDropdown = false;
  }

  onLangClicked() {
    this.showLangDropdown = !this.showLangDropdown;
    this.showMediaDropdown = false;
    this.showDropDown = false;
  }

  // onCompanyIconClicked(){
  //   this.showCompanyDropdown = !this.showCompanyDropdown;
  //   this.showMediaDropdown = false;
  //   this.showDropDown = false;
  // }

  onSelect(event : any){
    this.router.navigateByUrl('company-profile-detail-view/' + this.newsTitleEncodedService.getEncodedTitle(event.item.companyName +'-'+ event.item.companyId?.toString()))
    this.showCompanyDropdown = false;
    this.showSearchDropdown = false
  }
}
