export class UserModel {
  constructor(
    private idToken: string
  ) {}

  public getToken(expirationIn: number) {
    if (!expirationIn || expirationIn <= 0) {
      return null;
    }
    return this.idToken;
  }
}
