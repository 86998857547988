import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../auth.service";
import {RoutersObject} from "../../../utils/constants/routers-object";


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  private loginRoute: string = RoutersObject.login;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.user.value === null && this.authService.checkIfUserIsAuthenticated === false) {
      this.router.navigate([this.loginRoute]).then();
      return false;
    }
    return true;
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => {
  return inject(AuthGuardService).canActivate(next, state);
}
