import {default as feRoutes} from "./fe-routes.json";

export let RoutersObject = {
  home: feRoutes.routes.app.publicModule.children.home.route,
  companies: feRoutes.routes.app.publicModule.children.companies.route,
  companyProfileDetailView: feRoutes.routes.app.publicModule.children.companyProfileDetailView.route,
  companyProfileDetailViewWithStepBack: '..' + feRoutes.routes.app.publicModule.children.companyProfileDetailView.route,
  viewFundingRounds: feRoutes.routes.app.publicModule.children.viewFundingRounds.route,
  viewFundingRoundsWithStepBack: '../..' +  feRoutes.routes.app.publicModule.children.viewFundingRounds.route,
  viewOrderBook: feRoutes.routes.app.publicModule.children.viewOrderBook.route,
  viewOrderBookWithStepBack: '../..' +  feRoutes.routes.app.publicModule.children.viewOrderBook.route,
  investors: feRoutes.routes.app.publicModule.children.investors.route,
  finTech: feRoutes.routes.app.publicModule.children.fintech.route,
  individualInvestorProfileView: feRoutes.routes.app.publicModule.children.individualInvestorProfileDetailView.route,
  individualInvestorProfileViewWithBackStep: '..' + feRoutes.routes.app.publicModule.children.individualInvestorProfileDetailView.route,
  institutionalInvestorProfileView: feRoutes.routes.app.publicModule.children.institutionalInvestorProfileDetailView.route,
  institutionalInvestorProfileViewWithBackStep: '..' + feRoutes.routes.app.publicModule.children.institutionalInvestorProfileDetailView.route,
  opportunities: feRoutes.routes.app.publicModule.children.opportunities.route,
  opportunitiesOffers: feRoutes.routes.app.publicModule.children.opportunitiesOffers.route,
  news: feRoutes.routes.app.publicModule.children.news.route,
  contactUs: feRoutes.routes.app.publicModule.children.contactUs.route,
  smeLoans: feRoutes.routes.app.publicModule.children.smeLoans.route,
  selectPackage: feRoutes.routes.app.smeLoans.children.select_package.route,
  sp: feRoutes.routes.app.publicModule.children.sp.route,
  sitemap: feRoutes.routes.app.publicModule.children.sitemap.route,
  termsAndConditions: feRoutes.routes.app.publicModule.children.termsAndCondition.route,

  login: feRoutes.routes.app.authenticationModule.children.login.route,
  forgetPassword: feRoutes.routes.app.authenticationModule.children.forgetPassword.route,
  successReset: feRoutes.routes.app.authenticationModule.children.successReset.route,
  resetPassword: feRoutes.routes.app.authenticationModule.children.resetPassword.route,
  successPasswordChange: feRoutes.routes.app.authenticationModule.children.successPasswordChange.route,

  registration: feRoutes.routes.app.registrationModule.children.register.route,
  shareholderAccountType: feRoutes.routes.app.registrationModule.children.shareholderAccountType.route,
  individualShareholder: feRoutes.routes.app.registrationModule.children.individualShareholder.route,
  institutionalShareholder: feRoutes.routes.app.registrationModule.children.institutionalShareholder.route,
  shareholderRegistrationSubmission: feRoutes.routes.app.registrationModule.children.shareholderRegistrationSubmission.route,
  companyRegistration: feRoutes.routes.app.registrationModule.children.companyRegistration.route,
  companyRegistrationSubmission: feRoutes.routes.app.registrationModule.children.companyRegistrationSubmission.route,
  investorAccountType: feRoutes.routes.app.registrationModule.children.investorAccountType.route,
  individualInvestor: feRoutes.routes.app.registrationModule.children.individualInvestor.route,
  institutionalInvestor: feRoutes.routes.app.registrationModule.children.institutionalInvestor.route,
  investorRegistrationSubmission: feRoutes.routes.app.registrationModule.children.investorRegistrationSubmission.route,
  financialConsultantRegistration: feRoutes.routes.app.registrationModule.children.financialConsultantRegistration.route,

  dashboard: feRoutes.routes.app.dashboardsModule.route,
  dashboardCompanyUser: feRoutes.routes.app.dashboardsModule.children.company.route,
  dashboardShareholderUser: feRoutes.routes.app.dashboardsModule.children.shareholder.route,
  dashboardInvestorUser: feRoutes.routes.app.dashboardsModule.children.investor.route,
  dashboardBuyerUser: feRoutes.routes.app.dashboardsModule.children.buyer.route,
  dashboardServiceProviderCompanyUser: feRoutes.routes.app.dashboardsModule.children.serviceProvider.route,

  companyHome: feRoutes.routes.app.companyUserModule.children.home.route,
  companyFindInvestors: feRoutes.routes.app.companyUserModule.children.findInvestors.route,
  companyNewsAndAnnouncements: feRoutes.routes.app.companyUserModule.children.companyNewsAndAnnouncements.route,
  companyMyRequests: feRoutes.routes.app.companyUserModule.children.myRequests.route,
  companyOtherRequests: feRoutes.routes.app.companyUserModule.children.otherRequests.route,
  companyUserProfile: feRoutes.routes.app.companyUserModule.children.companyUserProfile.route,
  companyUpdateUserProfile: feRoutes.routes.app.companyUserModule.children.updateCompanyUserProfile.route,
  companyCompanyProfileView: feRoutes.routes.app.companyUserModule.children.companyProfileView.route,
  companyUpdateCompanyProfile: feRoutes.routes.app.companyUserModule.children.updateCompanyProfile.route,
  newsWithStepBack: feRoutes.routes.app.companyUserModule.children.news.route,
  companyNewsAndAnnouncementsAll: feRoutes.routes.app.companyUserModule.children.newsAndAnnouncementAll.route,


  newsAndAnnouncementsAll: '../news-and-announcement-all',
  newsAndAnnouncementsAllWithStepBack: '../../../news-and-announcement-all',
  newsArticle: '/news/article',
  newsAll: '/news/all',
  newsArticleDetail: '/news' + feRoutes.routes.app.newsModule.children.newsArticleDetail.route,
  relatedNewsAll: '../../../news/all',
  newsPageWithStepBack: '../' + feRoutes.routes.app.publicModule.children.news.route,
  newsArticleWithStepBack: '../../news/article',
  newsArticleSingleWithStepBack: '../news/article',
  fintechDetailView: '../fintech-detail-view-beta',
  fintechDetailViewStepBack: '../../fintech-detail-view-beta',
  companyUserProfileNewsAll: '../news/all',
  mediaCenterHome:'/media-hub',

  shareholderHome: feRoutes.routes.app.shareholderUserModule.children.home.route,
  shareholderCompanies: feRoutes.routes.app.shareholderUserModule.children.companies.route,
  shareholderMyCompanies: feRoutes.routes.app.shareholderUserModule.children.myCompanies.route,
  shareholderViewOrderBook: '../' +  feRoutes.routes.app.publicModule.children.viewOrderBook.route,
  shareholderFollowingCompanies: feRoutes.routes.app.shareholderUserModule.children.followingCompanies.route,
  shareholderMyRequests: feRoutes.routes.app.shareholderUserModule.children.myRequests.route,
  shareholderOtherRequests: feRoutes.routes.app.shareholderUserModule.children.otherRequests.route,
  shareholderIndividualProfile: feRoutes.routes.app.shareholderUserModule.children.individualShareholderProfile.route,
  shareholderUpdateIndividualProfile: feRoutes.routes.app.shareholderUserModule.children.updateIndividualShareholderProfile.route,
  shareholderInstitutionalProfile: feRoutes.routes.app.shareholderUserModule.children.institutionalShareholderProfile.route,
  shareholderUpdateInstitutionalProfile: feRoutes.routes.app.shareholderUserModule.children.updateInstitutionalShareholderProfile.route,

  investorHome: feRoutes.routes.app.investorUserModule.children.home.route,
  investorCompanies: feRoutes.routes.app.investorUserModule.children.companies.route,
  investorMyCompanies: feRoutes.routes.app.investorUserModule.children.myCompanies.route,
  investorViewOrderBook: feRoutes.routes.app.investorUserModule.children.viewOrderBook.route,
  investorFollowingCompanies: feRoutes.routes.app.investorUserModule.children.followingCompanies.route,
  investorMyRequests: feRoutes.routes.app.investorUserModule.children.myRequests.route,
  investorOtherRequests: feRoutes.routes.app.investorUserModule.children.otherRequests.route,
  investorIndividualProfile: feRoutes.routes.app.investorUserModule.children.individualInvestorProfile.route,
  investorUpdateIndividualProfile: feRoutes.routes.app.investorUserModule.children.updateIndividualInvestorProfile.route,
  investorInstitutionalProfile: feRoutes.routes.app.investorUserModule.children.institutionalInvestorProfile.route,
  investorUpdateInstitutionalProfile: feRoutes.routes.app.investorUserModule.children.updateInstitutionalInvestorProfile.route,

  peTradingMyCompaniesDashboard: feRoutes.routes.app.peTradingModule.children.myCompanyDashboard.route,
  peTradingOpportunities: feRoutes.routes.app.peTradingModule.children.opportunities.route,
  peTradingMyCompanies: feRoutes.routes.app.peTradingModule.children.myCompanies.route,
  peTradingMyOrders: feRoutes.routes.app.peTradingModule.children.myOrders.route,
  peTradingMyTrades: feRoutes.routes.app.peTradingModule.children.myTrades.route,

  fintechScreener: feRoutes.routes.app.publicModule.children.fintech.children.fintechScreener.route,
  fintechTopNews: feRoutes.routes.app.publicModule.children.fintech.children.topNews.route,
  fintechOnlineServices: feRoutes.routes.app.publicModule.children.fintech.children.onlineServices.route,
  fintechFundingRound: feRoutes.routes.app.publicModule.children.fintech.children.fundingRound.route,
  fintechRoundDetails: feRoutes.routes.app.publicModule.children.fintech.children.fundingRoundDetails.route,
  fintechSearchCompany: feRoutes.routes.app.publicModule.children.fintech.children.searchCompany.route,

  sharikatMubasherNews: feRoutes.routes.app.publicModule.children.sharikatMubasherNews.route,
  newsReports: feRoutes.routes.app.publicModule.children.newsReports.route,
  interviews: feRoutes.routes.app.publicModule.children.interviews.route,
  events: '/media-hub/events',
  expertsThoughts: '/media-hub/experts-thoughts',
  podcasts: '/media-hub/podcasts',
}
