import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {Meta, Title} from "@angular/platform-browser";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})

export class MetaTagsService {

  constructor(
    private meta: Meta,
    private title: Title,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }

  ngOnInit() {
  }

  updateMetaTags(linkToShare: string, newsContentTitle: string, newsContentDescription: string, thumbnailImageUrl: string) {
    this.meta.updateTag({
      name: 'description',
      content: newsContentDescription == '' ? 'Sharikat Mubasher' : newsContentDescription
    });

    this.meta.updateTag({
      property: 'og:url',
      content: linkToShare == '' ? 'https://sharikatmubasher.com/' : linkToShare
    });
    this.meta.updateTag({
      property: 'og:title',
      content: newsContentTitle == '' ? 'Sharikat Mubasher' : newsContentTitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: newsContentDescription == '' ? 'Sharikat Mubasher' : newsContentDescription
    });
    this.meta.updateTag({
      property: 'og:image',
      content: thumbnailImageUrl == '' ? 'https://sharikatmubasher.com/assets/images/logo.png' : thumbnailImageUrl
    });

    this.meta.updateTag({
      name: 'twitter:url',
      content: linkToShare == '' ? 'https://sharikatmubasher.com/' : linkToShare
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: newsContentTitle == '' ? 'Sharikat Mubasher' : newsContentTitle
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: newsContentDescription == '' ? 'Sharikat Mubasher' : newsContentDescription
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: thumbnailImageUrl == '' ? 'https://sharikatmubasher.com/assets/images/logo.png' : thumbnailImageUrl
    });
    this.meta.updateTag({
      name: 'twitter:image:alt',
      content: thumbnailImageUrl == '' ? 'https://sharikatmubasher.com/assets/images/logo.png' : thumbnailImageUrl
    });
  }

  updateCanonicalUrl(url: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('link[rel="canonical"]')?.setAttribute('href', url);
    }
  }

  updateMetaDescriptionAndKeywords(description: string, keywords: string,) {
    this.meta.updateTag({
      name: 'description',
      content: description == '' ? 'Sharikat Mubasher' : description
    });

    this.meta.updateTag({
      name: 'keywords',
      content: keywords == '' ? 'Sharikat Mubasher' : keywords
    });
  }

  updateCompanyProfileDescriptionAndKeywords(description: string, keywords: string, companyName: string) {
    this.meta.updateTag({
      name: 'description',
      content: description == '' ? 'Sharikat Mubasher' : companyName + description
    });

    this.meta.updateTag({
      name: 'keywords',
      content: keywords == '' ? 'Sharikat Mubasher' : companyName + ", " + companyName + keywords
    });
  }

  updateTitle(pageTitle: string) {
      this.title.setTitle(pageTitle);
  }
}
